import { render } from "./SendingTestModal.vue?vue&type=template&id=0a998d6a&scoped=true"
import script from "./SendingTestModal.vue?vue&type=script&lang=ts"
export * from "./SendingTestModal.vue?vue&type=script&lang=ts"

import "./SendingTestModal.vue?vue&type=style&index=0&id=0a998d6a&lang=scss&scoped=true"
script.render = render
script.__scopeId = "data-v-0a998d6a"

export default script
import QDialog from 'quasar/src/components/dialog/QDialog.js';
import QCard from 'quasar/src/components/card/QCard.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QForm from 'quasar/src/components/form/QForm.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QStepper from 'quasar/src/components/stepper/QStepper.js';
import QStep from 'quasar/src/components/stepper/QStep.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QTooltip from 'quasar/src/components/tooltip/QTooltip.js';
import QRadio from 'quasar/src/components/radio/QRadio.js';
import QTable from 'quasar/src/components/table/QTable.js';
import QTr from 'quasar/src/components/table/QTr.js';
import QTd from 'quasar/src/components/table/QTd.js';
import QCheckbox from 'quasar/src/components/checkbox/QCheckbox.js';
import QSpace from 'quasar/src/components/space/QSpace.js';
import qInstall from "../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QDialog,QCard,QCardSection,QForm,QBtn,QStepper,QStep,QIcon,QTooltip,QRadio,QTable,QTr,QTd,QCheckbox,QSpace});
